import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { FrontDesk_URL } from '../../constant';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PdfPrintReceipt = () => {

  const [Name, setName] = useState("");
  const [PatientMobile, setPatientMobile] = useState("");
  const [PatientUHID, setPatientUHID] = useState("");
  const [PatientDOB, setPatientDOB] = useState("");
  const [PatientGender, setPatientGender] = useState("");
  const [Address, setAddress] = useState("");
  const [CentreName, setCentreName] = useState("");
  const [Visitno, setVisitno] = useState("");
  const [VisitDate, setVisitDate] = useState("");
  const [ReferedBy, setReferedBy] = useState("");
  const [FrontdeskUserName, setFrontdeskUserName] = useState("");
  const [OrderNo, setOrderNo] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [patientservicedetail, setPatientservicedetail] = useState([]);
  const [OrderDetails, setOrderDetails] = useState([]);
  const [TotalAmount, setTotalAmount] = useState("0.00");
  const [OldInvoice, setOldInvoice] = useState("N/A");
  const [TotalPaidAmount, setTotalPaidAmount] = useState("0.00");
  const [DiscountAmount, setDiscountAmount] = useState("0.00");
  const [PendingAmount, setPendingAmount] = useState("0.00");
  const [AmountInWords, setAmountInWords] = useState("0.00");
  const [ContactNo, setContactNo] = useState("");
  const [EamilId, setEamilId] = useState("");
  const [CentreAddress, setCentreAddress] = useState("");
  const [CompanyCINNo, setCompanyCINNo] = useState("");
  const [RegisteredOffice, setRegisteredOffice] = useState("");
  const [CoprateOffice, setCoprateOffice] = useState("");
  const [CentreLocations, setCentreLocations] = useState("");
  const [OurServices, setOurServices] = useState("");
  const [CentreGSTNo, setCentreGSTNo] = useState("");
  const [visitingId, setVisitingId] = useState(localStorage.getItem('vId'));
  const [HideBillingStationery, setHideBillingStationery] = useState(true);

  useEffect(() => { GetReceipt(); }, [])
  useEffect(() => {
    if (Visitno == "" || Visitno == null || Visitno == undefined) { }
    else { UploadBill(); }
  }, [Visitno])

  const GetReceipt = async () => {
    try {
      let url = FrontDesk_URL + 'master/getPrintReceipt/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('vId') + '/' + localStorage.getItem('CentreSNo')
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          let hidebillstat = true;
          if (data.Data.HideBillingStationery == "False" || data.Data.HideBillingStationery == "false" || data.Data.HideBillingStationery == "0") { hidebillstat = false; }
          setName(data.Data.Name);
          setPatientMobile(data.Data.PatientMobile);
          setPatientUHID(data.Data.PatientUHID);
          setPatientDOB(data.Data.PatientDOB);
          setPatientGender(data.Data.PatientGender);
          setAddress(data.Data.Address);
          setCentreName(data.Data.CentreName);
          setVisitDate(data.Data.VisitDate);
          setReferedBy(data.Data.ReferedBy);
          setFrontdeskUserName(data.Data.FrontdeskUserName);
          setHideBillingStationery(hidebillstat);
          setPatientservicedetail(data.Data.regestrationServicedetails);
          if (data.Data.OrderDetails == null || data.Data.OrderDetails == undefined) { setOrderDetails([]);/*window.top.close();*/ }
          else { setOrderDetails(data.Data.OrderDetails) }
          setOrderNo(data.Data.OrderNo);
          setInvoiceNo(data.Data.InvoiceNo);
          setTotalAmount(data.Data.OrderInfo.TotalAmount);
          setOldInvoice(data.Data.OldInvoice);
          setTotalPaidAmount(data.Data.OrderInfo.TotalPaidAmount);
          setDiscountAmount(data.Data.OrderInfo.TotalDiscount);
          setPendingAmount(data.Data.OrderInfo.PendingAmount);
          setAmountInWords(data.Data.OrderInfo.AmountInWords);
          setContactNo(data.Data.ContactNo);
          setEamilId(data.Data.EmailId);
          setCentreAddress(data.Data.CentreAddress);
          setCompanyCINNo(data.Data.CompanyCINNo);
          setRegisteredOffice(data.Data.RegisteredOffice);
          setCoprateOffice(data.Data.CoprateOffice);
          setCentreLocations(data.Data.CentreLocations);
          setOurServices(data.Data.OurServices);
          setCentreGSTNo(data.Data.CentreGSTNo);
          setVisitno(data.Data.Visitno);
        }
        else {
          setName("");
          setPatientMobile("");
          setPatientUHID("");
          setPatientDOB("");
          setPatientGender("");
          setAddress("");
          setCentreName("");
          setVisitno("");
          setVisitDate("");
          setReferedBy("");
          setFrontdeskUserName("");
          setHideBillingStationery(true);
          setPatientservicedetail([]);
          setOrderDetails([])
          setOrderNo("");
          setInvoiceNo("");
          setTotalAmount("0.00");
          setOldInvoice("N/A");
          setTotalPaidAmount("0.00");
          setDiscountAmount("0.00");
          setPendingAmount("0.00");
          setAmountInWords("0.00");
          setContactNo("");
          setEamilId("");
          setCentreAddress("");
          setCompanyCINNo("");
          setRegisteredOffice("");
          setCoprateOffice("");
          setCentreLocations("");
          setOurServices("");
          setCentreGSTNo("");
        }
      }).catch(function (error) {
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }
    catch (error) {
      SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
    }
  }
  const UploadBill = async () => {
    const element = document.getElementById('pdf-content'); // Replace 'pdf-content' with the ID of the element you want to capture
    html2canvas(element).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var pdf = new jsPDF("l", "mm", "", true);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "png", 0, 0, width, height);
      var foutput = pdf.output("datauristring");
      var base64img = foutput.split("base64,");
      try {
        const requestOptions = { Image: base64img[1], SNo: Visitno, extension: ".pdf", pathflag: "bill", visitid: visitingId };
        let url = FrontDesk_URL + 'master/UploadPDFImage_Root'
        await axios.post(url, requestOptions).then((response) => {
          const data = response.data;
          if (data.Success) { } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
      }
      catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    });
  }
  // const UploadBill = async () => {
  //   const element = document.getElementById('pdf-content'); // Replace 'pdf-content' with the ID of the element you want to capture
  //   html2canvas(element).then(async (canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     var pdf = new jsPDF('l', 'mm', [500, 280],true);
  //     var marginLeft = 35;
  //     var marginRight = 0
  //     pdf.addImage(imgData, "png", marginLeft, marginRight);
  //     var foutput = pdf.output("datauristring");
  //     var base64img = foutput.split("base64,");
  //     try {
  //       const requestOptions = { Image: base64img[1], SNo: Visitno, extension: ".pdf", pathflag: "bill", visitid: visitingId };
  //       let url = FrontDesk_URL + 'master/UploadPDFImage_Root'
  //       await axios.post(url, requestOptions).then((response) => {
  //         const data = response.data;
  //         if (data.Success) { } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
  //       }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
  //     }
  //     catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  //   });
  // }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  return (
    <>
      <div id="pdf-content">
        <Container fluid className="bill-container">
          <Row>
            <Col md={12}>
              {/* <Card> */}
              <Card.Body style={{ paddingTop: '10px' }}>
                <div className="formWrapper">
                  <header className="row mb-4 pb-3">
                    {
                      HideBillingStationery == true ?
                        <Col xs={3}>&nbsp;</Col>
                        :
                        <Col xs={3}>
                          <img
                            src="/images/Atulaya-Logo.png"
                            className="img-fluid"
                            alt="Atulaya Healthcare"
                            width="250"
                            height="148"
                          />
                        </Col>
                    }
                    <Col xs={9} className="text-right">
                      <h4 className="fw-500">
                        {CentreName} <br />
                      </h4>
                      <p className="mb-0">
                        Local Address: <strong>{CentreAddress}</strong>
                      </p>
                      <p className="mb-0">
                        Center Ph. No: <strong>{ContactNo}</strong>
                      </p>
                      <p className="mb-0">
                        CIN No.: <strong>{CompanyCINNo}</strong>
                      </p>
                      <p className="mb-0">
                        GST No.: <strong>{CentreGSTNo}</strong>
                      </p>
                    </Col>
                  </header>
                  <section className="row align-items-center mb-3">
                    <Col xs={7}>
                      <h3 className="text-right fw-bold">Invoice</h3>
                    </Col>
                    <Col xs={5} className="text-right">
                      <Barcode value={Visitno} />
                    </Col>
                  </section>
                  <section className="customer-info row mb-4">
                    <Col xs={6} className="pr-1">
                      <div className="border p-3">
                        <Row>
                          <Col xs={4}>
                            <p>Name</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {Name}</strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <p>Age/Gender</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {PatientDOB + '/' + PatientGender}</strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <p>Contact No</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {PatientMobile}</strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <p>Address</p>
                          </Col>
                          <Col xs={8}>
                            <p className="d-flex">
                              <strong>: </strong>
                              <strong className="pl-1">
                                {Address}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <p>UHID</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {PatientUHID}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xs={6} className="pl-1">
                      <div className="border p-3 h-100">
                        <Row>
                          <Col xs={4}>
                            <p>Visit No</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {Visitno}</strong>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <p>Visit/Reg Date</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {VisitDate}</strong>
                            </p>
                          </Col>
                        </Row>
                        {
                          InvoiceNo === "" ? null :
                            <Row>
                              <Col xs={4}>
                                <p>Bill No</p>
                              </Col>
                              <Col xs={8}>
                                <p>
                                  <strong>: {InvoiceNo}</strong>
                                </p>
                              </Col>
                            </Row>
                        }
                        <Row>
                          <Col xs={4}>
                            <p>Referred By</p>
                          </Col>
                          <Col xs={8}>
                            <p>
                              <strong>: {ReferedBy}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </section>
                  <div className="main">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col"> #</th>
                          <th scope="col"> Department </th>
                          <th scope="col"> Service Code </th>
                          <th scope="col"> Service Name </th>
                          <th scope="col"> SAC/HSN Code </th>
                          <th scope="col"> MRP </th>
                          <th scope="col"> Discount </th>
                          <th scope="col"> CGST </th>
                          <th scope="col"> SGST </th>
                          <th scope="col"> Invoice Value </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          patientservicedetail != null && patientservicedetail.length > 0 ?
                            <>
                              {
                                patientservicedetail.map((d, index) =>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{d.Department}</td>
                                    <td>{d.ServiceCode}</td>
                                    <td>{d.ServiceName}</td>
                                    <td>{d.HSNCode}</td>
                                    <td>{d.MRP}</td>
                                    <td>{d.discount}</td>
                                    <td>{d.CGSTAmount}</td>
                                    <td>{d.SGSTAmount}</td>
                                    <td>{d.Total}</td>
                                  </tr>
                                )
                              }
                            </>
                            : null
                        }
                      </tbody>
                    </table>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col"> Receipt No</th>
                          <th scope="col"> Payment </th>
                          <th scope="col"> Mode </th>
                          <th scope="col"> Amount </th>
                          <th scope="col"> Received By </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          OrderDetails != [] ?
                            <>
                              {
                                OrderDetails.map((d, index) =>
                                  <tr>
                                    <td>{d.ReceiptNo}</td>
                                    <td>{d.PayDate}</td>
                                    <td>{d.PayMode}</td>
                                    <td>{d.PaidAmount}</td>
                                    <td>{d.Name}</td>
                                  </tr>
                                )
                              }
                            </>
                            : null
                        }
                      </tbody>
                    </table>
                    <div className="row mb-2"></div>
                    <div className="row">
                      <div className="col-sm-12 text-right"> Total Amount : <b>{TotalAmount}</b></div>
                      {parseFloat(DiscountAmount) > 0 ? <div className="col-sm-12 text-right"> Discount Amount : <b>{DiscountAmount}</b></div> : null}
                      {parseFloat(PendingAmount) > 0 ? <div className="col-sm-12 text-right"> Pending Amount : <b>{PendingAmount}</b></div> : null}
                      <div className="col-sm-12 text-right"> Paid Amount : <b>{TotalPaidAmount}</b></div>
                    </div>
                    <div className="row mb-2"></div>
                    <div className="row">
                      <div className="col-sm-6"><b>Received with thanks:</b>{AmountInWords}</div>
                      <div className="col-sm-6 text-right">
                        <b>{FrontdeskUserName}</b><br></br>Authorized Signature :
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12" style={{ color: "#F44336" }}><b>LinkedInvoice:</b>{OldInvoice}</div>
                    </div>
                    {
                      HideBillingStationery == true ? null :
                        <>
                          <div>
                            {{ EamilId } != "" ? <p>For any query, kindly get in touch with us on {EamilId}</p> : <p>For any query, kindly get in touch with us on healthcare@atulaya.com</p>}
                          </div>
                          <div className="row mb-2 mt-4">
                            <div className='col-sm-12'>
                              <p className='mb-2'><b>Locations:</b>{CentreLocations}</p>
                              {
                                RegisteredOffice == "" ? null : <p className='mb-2'><b>Registered Office:</b>{RegisteredOffice}</p>
                              }
                              {
                                CoprateOffice == "" ? null : <p className='mb-2'><b>Corporate Office:</b>{CoprateOffice}</p>
                              }
                              <p className='mb-2'><b>Services:</b>{OurServices}</p>
                              <p className='mb-2'>PS: This is a system generated document and does NOT require Original Signatures & Stamp. Printout of this document may be treated as ORIGINAL</p>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </div>
              </Card.Body>
              {/* </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}



export default PdfPrintReceipt
