import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import CommonToolTip from '../UI/ToolTips/CommonToolTip';
// import UploadToolTip from '../UI/ToolTips/UploadToolTip';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL, Image_Path } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import Select from 'react-select';
import TimeAgo from 'react-timeago';

var fdate = "";
var fdate1 = "";
var glblDocArr = [];
const SampleCollection = () => {
    let mobileNo = useRef();
    let patientName = useRef();
    let sampleStatus = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    let doctypee = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableColorData, setTableColorData] = useState([]);
    const history = useHistory();
    const [defaultDate, setDefaultDate] = useState("");
    const [selectedDate, setSelectedDate] = useState();
    const [selectedDate1, setSelectedDate1] = useState();
    const [openfilter, setOpenFilter] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [remarksModal, setRemarksModal] = useState();
    const [viewremarks, setViewRemarks] = useState([]);
    const [visitingid, setVisitingId] = useState("0");
    const [doctype, setDocType] = useState("0");
    const [doctypename, setDocTypeName] = useState("");
    const [documenttype, setDocumentType] = useState([]);
    const [tabledocdata1, setTableDocData1] = useState([]);
    const [visitid, setVisitId] = useState("0");
    const [viewreqfieldsModal, setViewReqFieldsModal] = useState();
    const [tabledocdata, setTableDocData] = useState([]);
    const [doccrntPageNO, setDocCrntPageNO] = useState(0);
    const [doccrntPageNO1, setDocCrntPageNO1] = useState(0);
    const [prescriptiondocument, setPrescriptionDocument] = useState("");
    const [isButtonAllLoading, setIsButtonAllLoading] = useState(false);
    const [viewitems, setViewItems] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [investigationremarks, setInvestigationRemarks] = useState([]);
    const [typeSelectedName, setTypeSelectedName] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const calendarRef = React.createRef();
    const calendarRef1 = React.createRef();
    let remarks = useRef();

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }

    const viewHandler = (vId) => {
        localStorage.setItem('vId', vId);
        history.push('/sample-collection/details');
    }
    const openRemarksModal = async (vId) => {
        setTypeSelected([]);
        GetViewItems(vId);
        setVisitingId(vId);
        setRemarksModal(!remarksModal);
        ViewAllRemarks(vId);
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        console.log(rowData);
        return <React.Fragment>
            <div>
                <ViewToolTip title="View Details" placement="top" onClick={() => viewHandler(rowData.VisitingId)} />
                <CommonToolTip title="Add/View Remarks" id={`Remarks${rowData.VisitingId}`} placement="top" class="fa fa-wechat mr-2" onClick={() => openRemarksModal(rowData.VisitingId)} />
                <CommonToolTip title="Upload Document" id={`Documentsss${rowData.VisitingId}`} placement="top" class="fa fa-upload" onClick={() => openSaveRequiredFieldModal(rowData.VisitingId)} />
            </div>
        </React.Fragment>
    }
    const AgeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowData.Age + ' | ' + rowData.Gender}</span>
            </div>
        </React.Fragment>
    }

    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit No.', editable: false },
        { dataField: 'MobileNo', text: 'Mobile No.', editable: false },
        { dataField: 'PatientName', text: 'Name', editable: false },
        // { dataField: 'Age', text: 'Age', editable: false },
        { dataField: 'Age', text: 'Age | Gender', editable: false, formatter: AgeFormatter },
        { dataField: 'OrderDate', text: 'Order On', editable: false },
        { dataField: 'Status', text: 'Status', editable: false },
        { dataField: 'Actions', isDummyField: true, text: 'Actions (Max 6 mb accepted)', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        const today = new Date();
        const today1 = new Date();
        const date = today.setDate(today.getDate() - 3);
        const date1 = today1.setDate(today1.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        const defaultValueTo = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
        //setDefaultDate(defaultValue);

        var datenew = new Date(defaultValue),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);

        var datenew1 = new Date(defaultValueTo),
            mnth1 = ("0" + (datenew1.getMonth() + 1)).slice(-2),
            day1 = ("0" + datenew1.getDate()).slice(-2);
        fdate1 = [datenew1.getFullYear(), mnth1, day1].join("-");
        setSelectedDate1(datenew1);

        BindGrid("", "", "0", fdate, fdate1);
        localStorage.removeItem('vId');
    }, []);
    const handleSearch = async () => {
        BindGrid(mobileNo.current.value, patientName.current.value, sampleStatus.current.value, fdate, fdate1);
        // BindGrid(mobileNo.current.value, patientName.current.value, sampleStatus.current.value, fromDate.current.value, toDate.current.value);
    }
    const BindGrid = async (mNo, pName, sStats, fDte, tDte) => {
        setIsLoading(true);
        const requestOptions = { MobileNo: mNo, PatientName: pName, Status: sStats, FromDate: fDte, ToDate: tDte, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser') };
        let url = FrontDesk_URL + 'samplecollection/getlist'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data._sampleList); setTableColorData(data.Data._sampleColorList); }
                else { setTableData([]); setTableColorData([]); }
                setIsLoading(false);
            }
            else { setTableData([]); setTableColorData([]); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const handleDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
    }
    const handleDateChange1 = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate1 = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate1(datenew);
    }
    const OpenFilter = () => {
        setOpenFilter(true);
    }
    const Reset = () => {
        setOpenFilter(false);
    }
    const ViewAllRemarks = async (vId) => {
        var visitid = (vId == null || vId == undefined || vId == "" ? "0" : vId)
        try {
            let url = FrontDesk_URL + 'master/viewremarks/' + visitid + '/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        if (data.Data._vwRemarksInvst != null) { setInvestigationRemarks(data.Data._vwRemarksInvst); }
                        else { setInvestigationRemarks([]); }
                        if (data.Data._vwRemarks != null) { setViewRemarks(data.Data._vwRemarks); }
                        else { setViewRemarks([]); }
                    }
                    else { setViewRemarks([]); setInvestigationRemarks([]); }
                } else { setViewRemarks([]); setInvestigationRemarks([]); }
            }).catch(function (error) { if (error.response) { setViewRemarks([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setViewRemarks([]); setInvestigationRemarks([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SaveRemarks = async () => {
        if (visitingid == "0") { SweetAlert.fire("Unable to get some values.Please refresh before proceed!"); return false; }
        if (typeSelected.length == 0 || typeSelected == []) { SweetAlert.fire("Please select investigation to proceed."); return false; }
        if (remarks.current.value == null || remarks.current.value == undefined || remarks.current.value == "") { SweetAlert.fire("Please enter remarks"); return false; }
        setIsButtonLoading(true);
        const requestOptions = { Remarks: remarks.current.value, VisitingId: visitingid, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser'), InvestigationId: typeSelected[0].value };
        let url = FrontDesk_URL + 'master/saveremarks'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    SweetAlert.fire({
                        title: 'Successful',
                        text: "Remarks saved succesfully",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) { window.location.reload(false); } else { window.location.reload(false); }
                    });
                }
                else { SweetAlert.fire("Unable to save data. Please try again."); }
                setIsButtonLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Check db and server logs", icon: "error" }); setIsButtonLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    }
    const openSaveRequiredFieldModal = (VisitingId) => {
        setDocType("0");
        setDocTypeName("");
        setDocumentType([]);
        glblDocArr = [];
        setTableDocData1([]);
        setVisitId(VisitingId);
        openRequiredFieldModal();
        GetDocumentType();
        ShowAllDocuments(VisitingId);
    }
    const openRequiredFieldModal = () => { setViewReqFieldsModal(!viewreqfieldsModal); }
    const GetDocumentType = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetDocumentType/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("No document type available"); }
                    else { setDocumentType(data.Data.map((d) => (<option key={d.DocumentId} value={d.DocumentId}>{d.DocumentName}</option>))); }
                }
                else { setDocumentType([]); SweetAlert.fire("Error occured due to: " + data.Data); }
            }).catch(function (error) {
                setDocumentType([]);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ShowAllDocuments = async (vId) => {
        try {
            let url = FrontDesk_URL + 'master/getalldocuments_doc/' + vId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setTableDocData(data.Data);
                    }
                    else { setTableDocData([]); }
                }
                else {
                    if (data.data != null) { SweetAlert.fire("Error: " + data.Data); }
                    else { SweetAlert.fire("Some error occured. Check logs!"); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const docPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const docPaginationOptions1 = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setDocCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const OpenDocument = async (VisitingId, Type, Document) => {
        var prename = "";
        var preurl = "";
        if (Type.toLowerCase() == "doctor prescription") {
            prename = "Prescription_" + VisitingId;
            preurl = Image_Path + "prescription/" + Document;
        }
        else {
            prename = "Prerequisite_" + VisitingId;
            preurl = Image_Path + "prerequisite/" + Document;
        }
        downloadFile(preurl, prename);
    }
    const iddocFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const iddocFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{doccrntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const docFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <button className='btn btn-link' style={{ color: '#e31e25' }} id={`Documents${rowData.RowId}`} onClick={() => OpenDocument(rowData.VisitingId, rowData.Type, rowData.Document)}>View Document</button>
            </div>
        </React.Fragment>
    }
    const docColumns = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const docColumns1 = [
        { dataField: '#', text: '#', editable: false, formatter: iddocFormatter1, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'Type', text: 'Type', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: docFormatter1, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const ChangeDocType = async (e) => {
        setDocType(e.target.value);
        setDocTypeName(e.target.options[e.target.selectedIndex].text);
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const UploadPrescription = async (e) => {
        const arryVal = e.target.files[0].name.split(".");
        const extension = arryVal[arryVal.length - 1];
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', doctype == "2" ? 'prescription' : 'prerequisite');
        formData.append('pathflag', doctype == "2" ? 'prescription' : 'prerequisite');
        const ImageName = Math.random();
        var imgName = "";
        if (doctype == "2") { imgName = ImageName + "prescription." + extension; }
        else { imgName = ImageName + "prerequisite." + extension; }
        formData.append('ImageName', imgName);
        let url = FrontDesk_URL + 'master/UploadImage_Root/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data", },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                var key = glblDocArr.length;
                glblDocArr.push({
                    RowId: (parseInt(key) + 1).toString(),
                    VisitingId: visitid,
                    Document: imgName,
                    Type: doctypename//doctype == "2" ? "Prescription" : "Others"
                });
                setPrescriptionDocument(imgName);
                setTableDocData1(glblDocArr);
                setDocType("0");
                doctypee.current.value = "0";
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const SaveRequiredFields = async () => {
        if (tabledocdata1.length == 0 || tabledocdata1 == [] || tabledocdata1 == null) {
            SweetAlert.fire("Please upload document first");
            return false;
        }
        var requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            VisitingId: visitid,
            PrescriptionDocument: JSON.stringify(tabledocdata1)//prescriptiondocument
        };
        setIsButtonAllLoading(true);
        try {
            let url = FrontDesk_URL + 'master/saveprescriptiondocument'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonAllLoading(false);
                const today = new Date();
                const today1 = new Date();
                const date = today.setDate(today.getDate() - 3);
                const date1 = today1.setDate(today1.getDate());
                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                const defaultValueTo = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
                if (data.Success) {
                    SweetAlert.fire({
                        title: 'Success',
                        text: "Data saved successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            openRequiredFieldModal();
                            var datenew = new Date(defaultValue),
                                mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                                day = ("0" + datenew.getDate()).slice(-2);
                            fdate = [datenew.getFullYear(), mnth, day].join("-");
                            setSelectedDate(datenew);

                            var datenew1 = new Date(defaultValueTo),
                                mnth1 = ("0" + (datenew1.getMonth() + 1)).slice(-2),
                                day1 = ("0" + datenew1.getDate()).slice(-2);
                            fdate1 = [datenew1.getFullYear(), mnth1, day1].join("-");
                            setSelectedDate1(datenew1);

                            BindGrid("", "", "0", fdate, fdate1);
                        }
                        else {
                            openRequiredFieldModal();
                            var datenew = new Date(defaultValue),
                                mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
                                day = ("0" + datenew.getDate()).slice(-2);
                            fdate = [datenew.getFullYear(), mnth, day].join("-");
                            setSelectedDate(datenew);

                            var datenew1 = new Date(defaultValueTo),
                                mnth1 = ("0" + (datenew1.getMonth() + 1)).slice(-2),
                                day1 = ("0" + datenew1.getDate()).slice(-2);
                            fdate1 = [datenew1.getFullYear(), mnth1, day1].join("-");
                            setSelectedDate1(datenew1);

                            BindGrid("", "", "0", fdate, fdate1);
                        }
                    })
                }
                else {
                    SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" });
                }
            }).catch(function (error) {
                setIsButtonAllLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonAllLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const GetViewItems = async (vid) => {
        var glblArr1 = [];
        try {
            let url = FrontDesk_URL + 'master/GetVisitItems/' + vid + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr1.push({ 'value': option.InvestigationId, 'label': option.Investigation, 'disabled': false }); });
                        setViewItems(glblArr1);
                    }
                    else {
                        setViewItems([]);
                    }
                }
                else { setViewItems([]); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        })
        setTypeSelected(typearr);
    }
    const StartThread = (InvestigationId, InvestigationName) => {
        var typearr1 = [];
        typearr1.push({
            value: InvestigationId,
            label: InvestigationName
        })
        setTypeSelected(typearr1);
    }
    const opentimepopup = (time) => {
        SweetAlert.fire(time);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Sample Collection" parent="Master" />
            <Container fluid>

                <div className='d-none d-lg-block'>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col lg={4} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Mobile No.:</Form.Label>
                                                    <Form.Control type='text' ref={mobileNo} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Name:</Form.Label>
                                                    <Form.Control type='text' ref={patientName} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Control as="select" id="sample" name="sample" ref={sampleStatus} >
                                                        <option value="-1" >All</option>
                                                        <option value="0" selected>Not Collected / Partial Collected / Rejected</option>
                                                        {/* <option value="1">Partial Collected</option> */}
                                                        <option value="2">Collected</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Date From</Form.Label>
                                                    {/* <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} /> */}
                                                    <DatePicker selected={selectedDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                        onChange={handleDateChange} ref={calendarRef}
                                                    />
                                                    <span className='calandericon1' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Date To</Form.Label>
                                                    {/* <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} /> */}
                                                    <DatePicker selected={selectedDate1} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                        onChange={handleDateChange1} ref={calendarRef1}
                                                    />
                                                    <span className='calandericon1' onClick={() => { calendarRef1.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className='mt15'>
                                            <Col md={12} className='text-center'>
                                                <Button variant='primary' onClick={handleSearch} >Search</Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Patient Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    {
                                        isLoading ?
                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <>
                                                <DataTables keyField='id' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                            </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className='d-lg-none'>
                    {
                        openfilter &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form>
                                                <Form.Row>
                                                    <Col lg={4} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Mobile No.:</Form.Label>
                                                            <Form.Control type='text' ref={mobileNo} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Name:</Form.Label>
                                                            <Form.Control type='text' ref={patientName} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Status</Form.Label>
                                                            <Form.Control as="select" id="sample" name="sample" ref={sampleStatus} >
                                                                <option value="-1" >All</option>
                                                                <option value="0" selected>Not Collected / Partial Collected / Rejected</option>
                                                                {/* <option value="1">Partial Collected</option> */}
                                                                <option value="2">Collected</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Date From</Form.Label>
                                                            {/* <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} /> */}
                                                            <DatePicker selected={selectedDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                                onChange={handleDateChange} ref={calendarRef}
                                                            />
                                                            <span className='calandericon1' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Date To</Form.Label>
                                                            {/* <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} /> */}
                                                            <DatePicker selected={selectedDate1} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                                onChange={handleDateChange1} ref={calendarRef1}
                                                            />
                                                            <span className='calandericon1' onClick={() => { calendarRef1.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row className='mt15'>
                                                    <Col md={12} className='text-center'>
                                                        <Button variant='secondary' className='mr-2' onClick={Reset}>Reset</Button>
                                                        <Button variant='primary' onClick={handleSearch} >Search</Button>
                                                    </Col>
                                                </Form.Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        openfilter === true ? null :
                            <>
                                <Row className='align-items-center mb-3'>
                                    <Col xs={12} className='text-right'>
                                        <Button variant='secondary' onClick={() => OpenFilter()}><i className='fa fa-sliders'></i>Filter</Button>
                                    </Col>
                                </Row>
                            </>
                    }
                    {
                        tableData.length > 0 ?
                            <>
                                {
                                    tableData.map((d, index) =>
                                        <Row>
                                            <Col md={12}>
                                                <Card className='routeCard'>
                                                    <Card.Header>
                                                        <Row className='align-items-center'>
                                                            <Col xs={6}>
                                                                <h3 className='routeSN'>{parseFloat(index) + 1}</h3>
                                                            </Col>
                                                            {/* <Col xs={6} className='text-right'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                                        <i className='fa fa-ellipsis-h'></i>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={() => UpdateStatus(d.Id, "2")}><i className="icofont icofont-clock-time"></i> Running Late</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={() => UpdateStatus(d.Id, "1")}><i className="icofont icofont-close"></i> Could Not Complete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col> */}
                                                        </Row>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <p><b>Visit No:</b> {d.VisitingCode}</p>
                                                                <p><b>Mobile No:</b> {d.MobileNo}</p>
                                                                <p><b>Name:</b> {d.PatientName}</p>
                                                                <p><b>Age | Gender:</b> {d.Age + ' | ' + d.Gender}</p>
                                                                <p><b>Order On:</b> {d.OrderDate}</p>
                                                                {
                                                                    tableColorData != null && tableColorData.length > 0
                                                                        ?
                                                                        <>
                                                                            {
                                                                                tableColorData.map((x, i) =>
                                                                                    <>
                                                                                        {
                                                                                            x.VisitingId === d.VisitingId ?
                                                                                                <>
                                                                                                    <table>
                                                                                                        <tr>
                                                                                                            <td><div className='colorCode1' style={{ 'backgroundColor': `${x.Color}` }} id={`dv${x.VisitingId + i}`}></div></td>
                                                                                                            <td><b>: {x.Qty}</b></td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        {d.Status != "Collected" ? <Button variant='primary' onClick={() => viewHandler(d.VisitingId)}>Collect Sample <i className="fa fa-search mr-2"></i></Button> : ""}
                                                        <Button className='mt-2' variant='secondary' onClick={() => openRemarksModal(d.VisitingId)}>Add/View Remarks <i className="fa fa-wechat mr-2"></i></Button>
                                                        <Button className='mt-2' variant='primary' onClick={() => openSaveRequiredFieldModal(d.VisitingId)}>Upload Document <i className="fa fa-file mr-2"></i></Button>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </>
                            :
                            <Row>
                                <Col md={12}>
                                    <Card className='routeCard'>
                                        <Card.Body>
                                            <h5 className="text-center mb-0">No data available</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
                <Modal isOpen={remarksModal} toggle={openRemarksModal} centered={true} size="lg">
                    <ModalHeader toggle={openRemarksModal}>Add/View Remarks</ModalHeader>
                    {
                        viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null :
                            <ModalBody className='modalvariation remarksection1'>
                                {
                                    viewremarks == [] || viewremarks == null || viewremarks == undefined || viewremarks.length == 0 ? null :
                                        investigationremarks.map((k) =>
                                            <Row>
                                                <Col md={12}>
                                                    <Card className='routeCard'>
                                                        <Card.Header>
                                                            <Row className='align-items-center'>
                                                                <Col xs={6}>
                                                                    <h3 className='routeSN'>{k.InvestigationName}</h3>
                                                                </Col>
                                                                <Col xs={6} className='text-right'>
                                                                    <Button size='sm' variant='primary' type='submit' className='pdremark' onClick={(e) => StartThread(k.InvestigationId, k.InvestigationName)}>Add Remark</Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {
                                                                viewremarks.map((d) =>
                                                                    k.InvestigationId == d.InvestigationId ?
                                                                        <Row className='mb-2'>
                                                                            <Col xs={12}>
                                                                                <p><b>{d.IsLISBSLFlag}:</b> {d.Remarks} <span style={{ color: 'grey', cursor: 'pointer', fontSize: '11px' }} onClick={(e) => opentimepopup(d.RemarksDateTime)}> [<TimeAgo date={d.RemarksDateTime} />]</span></p>
                                                                            </Col>
                                                                        </Row>
                                                                        : null
                                                                )}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                }

                            </ModalBody>
                    }
                    <ModalFooter className='remarksection2'>
                        <Row>
                            <Col md={12} className='mt-3'>
                                <Form.Group>
                                    <Form.Label>Investigation <span className='text-red'>*</span></Form.Label>
                                    <Select onChange={TypeChangeHandler}
                                        options={viewitems}
                                        value={typeSelected}
                                        placeholder='Select'
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Remarks <span className='text-red'>(Max length 500 characters allowed)</span></Form.Label>
                                    <Form.Control as="textarea" rows={3} ref={remarks} maxLength={500} id="remarksnew" name="remarks" />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-2 text-center'>
                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={SaveRemarks}>Save</Button>}
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
                {
                    viewreqfieldsModal &&
                    <Modal isOpen={viewreqfieldsModal} toggle={openRequiredFieldModal} centered={true} size="lg">
                        <ModalHeader toggle={openRequiredFieldModal}>View/Upload Documents</ModalHeader>
                        <ModalBody className='modalvariation'>
                            {
                                tabledocdata == [] || tabledocdata == null || tabledocdata.length == 0 ? null :
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <Card.Header>
                                                    <Row className='align-items-center'>
                                                        <Col xs={6}>
                                                            <h3 className='routeSN'>View Documents</h3>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <DataTables
                                                        keyField='RowId'
                                                        tableData={tabledocdata}
                                                        columns={docColumns}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(docPaginationOptions)} />

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                            }
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header>
                                            <Row className='align-items-center'>
                                                <Col xs={6}>
                                                    <h3 className='routeSN'>Upload Documents</h3>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Document Type <span className='text-red'>*</span></Form.Label>
                                                        <Form.Control as="select" ref={doctypee} onChange={(e) => { ChangeDocType(e) }}>
                                                            <option key="0" value="0">---Select---</option>
                                                            {documenttype}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    doctype == "0" ? null :
                                                        <Col md={4}>
                                                            <Form.Group>
                                                                <Form.Label>{doctypename} <span className='text-red'>*</span></Form.Label>
                                                                <div><input type="file" id='up_prescription' onChange={(e) => UploadPrescription(e)} /></div>
                                                            </Form.Group>
                                                        </Col>
                                                }
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {
                                        prescriptiondocument == "" || prescriptiondocument == null ? null :
                                            <Row>
                                                <Col md={12}>
                                                    <Card>
                                                        <Card.Header>
                                                            <Row className='align-items-center'>
                                                                <Col xs={6}>
                                                                    <h3 className='routeSN'>View Uploaded Documents</h3>
                                                                </Col>
                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <DataTables
                                                                keyField='RowId'
                                                                tableData={tabledocdata1}
                                                                columns={docColumns1}
                                                                noDataIndication={NoRecordFounds}
                                                                pagination={paginationFactory(docPaginationOptions1)} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='text-center'>
                                    <Button variant='secondary' className="mr-2" onClick={openRequiredFieldModal}>Cancel</Button>
                                    {
                                        prescriptiondocument == "" ? null :
                                            <>
                                                {
                                                    isButtonAllLoading ?
                                                        <LoadingButton variantName='primary' cssName="" /> :
                                                        <Button variant='primary' type='submit' onClick={(e) => SaveRequiredFields()}>Save</Button>
                                                }
                                            </>
                                    }
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default SampleCollection