import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { Color, FrontDesk_URL } from '../constant';
import { Spinner } from 'react-bootstrap';
import DashboardBanner from '../components/dashboard/DashboardBanner';
import Carousel from 'react-multi-carousel';

const Dashboard = () => {
    const [userName, setuserName] = useState(localStorage.UserName == undefined ? "" : localStorage.UserName);
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardbanners, setDashboardBanners] = useState([]);


    useEffect(() => {
        const centreId = localStorage.CentreSNo == undefined ? "0" : localStorage.CentreSNo;
        if (centreId != "0") { GetSettings(centreId); GetDashboardBanners(centreId); }
    }, []);
    const GetSettings = async (cId) => {
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + localStorage.LoggedInUser;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let showBalanceAmount = false; if (settingInfo.ShowBalanceAmount === "True") { showBalanceAmount = true; }
                    let hideReceiptRate = false; if (settingInfo.HideReceiptRate === "True") { hideReceiptRate = true; }
                    let batchRequired = false; if (settingInfo.BatchRequired === "True") { batchRequired = true; }
                    let showMRPBill = false; if (settingInfo.ShowMRPBill === "True") { showMRPBill = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    let showPROInRegistration = false; if (settingInfo.ShowPROInRegistration === "True") { showPROInRegistration = true; }
                    let showCentreDetailOnReport = false; if (settingInfo.ShowCentreDetailOnReport === "True") { showCentreDetailOnReport = true; }
                    let hideFooterOnWithoutLetterheadReport = false; if (settingInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFooterOnWithoutLetterheadReport = true; }
                    localStorage.removeItem('CreditLimit');
                    localStorage.setItem('CentreTypeId', settingInfo.CentreTypeId);
                    localStorage.setItem('PartnerTypeId', settingInfo.PartnerTypeId);
                    localStorage.setItem('HideBookingAmount', hideBookingAmount);
                    localStorage.setItem('ShowBalanceAmount', showBalanceAmount);
                    localStorage.setItem('HideReceiptRate', hideReceiptRate);
                    localStorage.setItem('BatchRequired', batchRequired);
                    localStorage.setItem('ShowMRPBill', showMRPBill);
                    localStorage.setItem('PrintDepartmentSlip', printDepartmentSlip);
                    localStorage.setItem('ShowPROInRegistration', showPROInRegistration);
                    localStorage.setItem('HideFooterOnWithoutLetterheadReport', hideFooterOnWithoutLetterheadReport);
                    localStorage.setItem('UHIDAbbreviation', settingInfo.UHIDAbbreviation);
                    localStorage.setItem('SAPCode', settingInfo.SAPCode);
                    localStorage.setItem('PaymentModeId', settingInfo.PaymentModeId);
                    localStorage.setItem('OtherLabRefNo', settingInfo.OtherLabRefNo);
                    localStorage.setItem('ReportEmail', settingInfo.ReportEmail);
                    localStorage.setItem('InvoiceEmail', settingInfo.InvoiceEmail);
                    localStorage.setItem('CreditLimit', settingInfo.CreditLimit);
                    localStorage.setItem('ReportingLimit', settingInfo.ReportingLimit);
                    localStorage.setItem('BookingLimit', settingInfo.BookingLimit);
                    localStorage.setItem('ParentCentreName', settingInfo.ParentCentreName);
                    localStorage.setItem('ParentCentreId', settingInfo.ParentCentreId);
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                    localStorage.setItem('CentreDiscountLimit', settingInfo.CentreDiscountLimit);
                    localStorage.setItem('DashboardMessage', (settingInfo.DashboardMessage == null || settingInfo.DashboardMessage == undefined || settingInfo.DashboardMessage == "") ? "" : settingInfo.DashboardMessage);
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const responsive = {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 }
    };
    const GetDashboardBanners = async (cId) => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getdashboardbanners/' + cId + '/' + localStorage.LoggedInUser;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data == null) { setDashboardBanners([]); }
                    else { setDashboardBanners(data.Data); }
                }
                else {
                    setIsLoading(false);
                    setDashboardBanners([]);
                    SweetAlert.fire("Some error occured.Check DB logs!");
                }
            }).catch(function (error) { setIsLoading(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Dashboard" parent="FrontDesk" />
            <Container fluid>
                <Row id="crsl">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={12} md={6}>
                                        <div class="text-center">
                                            <h1> Welcome {userName} ({localStorage.getItem('CentreName')})
                                                {
                                                    FrontDesk_URL === "https://devfrontdeskapi.atulaya.com/api/" ? <b style={{ color: 'red' }}>(UAT Env)</b> : null
                                                }
                                            </h1>
                                        </div>
                                    </Col>
                                </Form.Row>
                                {
                                    dashboardbanners.length > 0 ?
                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <Row className='justify-content-center align-items-center'>
                                                <Col lg={12}>
                                                    <Card className='textCarousel'>
                                                        <Card.Body>
                                                            <Carousel responsive={responsive} autoPlay={true} rewind={true} rewindWithAnimation={true} showDots={true} infinite={false} arrows={true} renderDotsOutside={true}>
                                                                {
                                                                    dashboardbanners.map((bnrdet) => {
                                                                        return (
                                                                            // <Carousel.Item key={bnrdet.DashboardCentreBannersId}>
                                                                            <DashboardBanner
                                                                                id={bnrdet.DashboardCentreBannersId}
                                                                                bannername={bnrdet.BannerName === "" ? "" : bnrdet.BannerName}
                                                                                banner={bnrdet.Banner === "" ? "" : bnrdet.Banner}
                                                                                thumbnail={bnrdet.Thumbnail === "" ? "" : bnrdet.Thumbnail}
                                                                            />
                                                                            // </Carousel.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Carousel>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        :
                                        null
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}


export default Dashboard;