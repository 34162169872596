import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from 'react-router';
import LoadingButton from '../commonComponent/LoadingButton';

var glblSlot = [];
const Appointments = () => {
    const history = useHistory();
    let mobileNo = useRef();
    let patientName = useRef();
    let sampleStatus = useRef();
    let visitsampleStatus = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    let refuhid = useRef();
    let refvisitid = useRef();
    let atime = useRef();
    let date = useRef();
    let updateatime = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [itemModal, setItemModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);
    const [fromdefaultDate, setFromDefaultDate] = useState("");
    const [viasource, setViaSource] = useState([]);
    const [slottiming, setSlotTiming] = useState([]);
    const [remarks, setRemarks] = useState("");
    const [defaultappdate, setDefaultAppDate] = useState("");
    const [defaultapptime, setDefaultAppTime] = useState("");
    const [allslottiming, setAllSlotTiming] = useState([]);
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [visitingid, setVisitingId] = useState("0");
    const openModal = () => { setItemModal(!itemModal); };

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="View Services" placement="top" linkId={"VwItm" + rowIndex} onClick={() => GetInfo(rowData)} faName="fa fa-info-circle mr-2"></ToolTip>
                &nbsp;
                <ToolTip title="Complete Payment" placement="top" linkId={"CmPymnt" + rowIndex} onClick={() => CallRegistrationScreen(rowData.PatientId, rowData.VisitingId)} faName="fa fa-credit-card mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const serviceTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.IsPathologyOrder === "1" && rowData.IsRadiologyOrder === "1" ? <span>Radiology + Pathology</span> :
                    rowData.IsPathologyOrder === "0" && rowData.IsRadiologyOrder === "1" ? <span>Radiology</span> :
                        rowData.IsPathologyOrder === "1" && rowData.IsRadiologyOrder === "0" ? <span>Pathology</span> : null
                }
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Appointment', text: 'Appointment Date & Time', editable: false },
        { dataField: 'VisitingCode', text: 'Visit No.', editable: false },
        { dataField: 'ReferrerCentreName', text: 'Referrer Centre Name', editable: false },
        { dataField: 'MobileNo', text: 'Mobile No.', editable: false },
        { dataField: 'PatientName', text: 'Patient Name', editable: false },
        { dataField: 'AddedOn', text: 'Booking Date', editable: false },
        { dataField: 'VisitSource', text: 'Visit Source', editable: false },
        { dataField: 'ServiceStatus', text: 'Service Status', editable: false },
        { text: 'Service Type', editable: false, formatter: serviceTypeFormatter },
        { dataField: 'Actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter }
    ];
    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const popuppaginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        const today = new Date();
        const today1 = new Date();
        const date = today.setDate(today.getDate() - 7);
        const date1 = today1.setDate(today1.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        const defaultValue2 = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
        setFromDefaultDate(defaultValue);
        setDefaultDate(defaultValue2);
        GetRadioViaSource();
        GetAllSlotTiming();
        BindGrid("", "", "", "", defaultValue, defaultValue2, "0", "");
    }, []);
    const handleSearch = async () => {
        if (fromDate.current.value == null || fromDate.current.value == undefined || fromDate.current.value == "") {
            if (toDate.current.value == null || toDate.current.value == undefined || toDate.current.value == "") { }
            else { SweetAlert.fire("Please enter from date or remove to date also"); return false; }
        }
        if (toDate.current.value == null || toDate.current.value == undefined || toDate.current.value == "") {
            if (fromDate.current.value == null || fromDate.current.value == undefined || fromDate.current.value == "") { }
            else { SweetAlert.fire("Please enter to date or remove from date also"); return false; }
        }
        var apptime = "0";
        if (atime.current.value == null || atime.current.value == undefined) { apptime = "0"; }
        else { apptime = atime.current.value; }
        BindGrid(mobileNo.current.value, patientName.current.value, refvisitid.current.value, refuhid.current.value, fromDate.current.value, toDate.current.value, visitsampleStatus.current.value, apptime);
    }
    const BindGrid = async (mNo, pName, vno, uhid, fDte, tDte, vstatus, apptime) => {
        setIsLoading(true);
        const requestOptions = {
            CentreId: localStorage.getItem('CentreSNo'),
            AppFromDate: fDte,
            AppToDate: tDte,
            MobileNo: mNo,
            PatientName: pName,
            VisitNo: vno,
            UHID: uhid,
            VisitStatus: vstatus,
            AppointmentTime: apptime
        };
        let url = FrontDesk_URL + 'master/getappointmentlist'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data); } else { setTableData([]) }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetInfo = async (rowData) => {
        var slotapdate = rowData.SlotAppointmentDate.split(" ")[0];
        GetSlotTiming(slotapdate);
        setPopupTableData(rowData.NotificationsItem);
        setRemarks((rowData.Remarks == null || rowData.Remarks == undefined) ? "" : rowData.Remarks);
        setDefaultAppDate((slotapdate == null || slotapdate == undefined) ? "" : slotapdate);
        setVisitingId(rowData.VisitingId);
        setTimeout(() => {
            if (rowData.SlotAppointmentTime == "" || rowData.SlotAppointmentTime == null || rowData.SlotAppointmentTime == undefined) { }
            else {
                if (glblSlot.length > 0) {
                    setDefaultAppTime(rowData.SlotAppointmentTime);
                    updateatime.current.value = rowData.SlotAppointmentTime;
                }
            }
        }, 1000);
        openModal();
    }
    const modalityTypeFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.ServiceType === "1" ? <span>Pathology</span> : <span>Radiology</span>}
            </div>
        </React.Fragment>
    }
    const popupColumns = [
        { dataField: 'Investigation', text: 'Investigation', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { text: 'Modality', editable: false, formatter: modalityTypeFormatter },
    ];
    const CallRegistrationScreen = async (PatientId, VisitingId) => {
        localStorage.setItem("WebsitePatientId", PatientId);
        localStorage.setItem("WebsiteVisitingId", VisitingId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/registration`, search: `` });
    }
    const GetRadioViaSource = async () => {
        try {
            let url = FrontDesk_URL + 'master/GetRadioViaSource'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    //setViaSource(data.Data);
                    setViaSource(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Source}</option>)));
                }
                else { setViaSource([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const DisableKeyPress = (e) => {
        e.preventDefault();
        return false;
    }
    const GetAllSlotTiming = async () => {
        let url = FrontDesk_URL + 'RadiologyAppointments/getallslottiming'
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                setAllSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
            }
            else { AxiosError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetSlotTiming = async (date) => {
        glblSlot = [];
        let url = FrontDesk_URL + 'RadiologyAppointments/getslottiming/' + logincentreid + '/' + loginuserid + '/' + date
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                glblSlot = data.Data;
                setSlotTiming(data.Data.map((d) => <option key={d.SlotTimingId} value={d.SlotTimingId}>{d.FromTime} - {d.ToTime}</option>));
            }
            else { AxiosError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const Refresh = async () => {
        window.location.reload();
    }
    const UpdateAppointmentDateAndTime = async () => {
        if (date.current.value == null || date.current.value == undefined || date.current.value == "") {
            SweetAlert.fire("Appointment date cannot be blank");
            return false;
        }
        if (visitingid == "0" || visitingid == null || visitingid == undefined) {
            SweetAlert.fire("Unable to get visit no. Please refresh or contact support!");
            return false;
        }
        setIsButtonLoading(true);
        const requestOptions = {
            VisitingId: visitingid,
            CentreId: logincentreid,
            LoginId: loginuserid,
            AppointmentDate: date.current.value,
            AppointmentTime: (updateatime.current.value == null || updateatime.current.value == undefined) ? "" : updateatime.current.value,
        };
        let url = FrontDesk_URL + 'master/updateappdate'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsButtonLoading(false);
                if (data.Data == "2000") {
                    SweetAlert.fire("Updated successfully"); openModal();
                    const today = new Date();
                    const today1 = new Date();
                    const date = today.setDate(today.getDate() - 7);
                    const date1 = today1.setDate(today1.getDate());
                    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                    const defaultValue2 = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
                    BindGrid("", "", "", "", defaultValue, defaultValue2, "0", "");
                } else { SweetAlert.fire("Unable to update.Please try again or contact support!"); }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime or check logs", icon: "error" }); setIsButtonLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); });
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Online Appointments" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Mobile Number:</Form.Label>
                                                <Form.Control type='text' min={0} id="mobilenumber" name="mobilenumber" ref={mobileNo} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Visit No:</Form.Label>
                                                <Form.Control type="text" id="visitid" name="visitid" ref={refvisitid} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>UHID:</Form.Label>
                                                <Form.Control type="text" id="uhid" name="uhid" ref={refuhid} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Patient Name:</Form.Label>
                                                <Form.Control type="text" id="patientname" name="patientname" ref={patientName} />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control as="select" id="sample" name="sample" ref={sampleStatus} >
                                                    <option value="2" selected>All</option>
                                                    <option value="0">Hold Completed</option>
                                                    <option value="1">Hold Appointment</option>
                                                    <option value="-1">Order Cancelled</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Visit Source</Form.Label>
                                                <Form.Control as="select" id="sample" name="sample" ref={visitsampleStatus} >
                                                    <option key="0" value="0" selected>All</option>
                                                    {viasource}
                                                    {/* <option value="1">Website</option>
                                                    <option value="2">Call Centre</option>
                                                    <option value="3">Radiology Appointment</option>
                                                    <option value="4">Centre Visit</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Appointment From Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={fromdefaultDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Appointment To Date</Form.Label>
                                                <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className='animate-chk'>
                                                <Form.Label>Appointment Time</Form.Label>
                                                <Form.Control as="select" name="apptime" ref={atime} onKeyPress={(e) => DisableKeyPress(e)}>
                                                    <option key="" value="">---Select---</option>
                                                    {allslottiming}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button variant='secondary' onClick={Refresh} className='mr-2'>Refresh</Button>
                                            <Button variant='primary' onClick={handleSearch} >Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Patient Detail</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='VisitingId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {itemModal &&
                    <Modal isOpen={itemModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Investigations</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            {
                                                remarks == "" ? null :
                                                    <Row>
                                                        <Col xs={12} className='mb-3'>
                                                            <h6><b>Remarks:</b> {remarks}</h6>
                                                        </Col>
                                                    </Row>
                                            }
                                            <DataTables keyField='VisitingId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(popuppaginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form>
                                                <Form.Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Appointment Date</Form.Label>
                                                            <Form.Control type="date" id="date" name="date" ref={date} defaultValue={defaultappdate} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className='animate-chk'>
                                                            <Form.Label>Appointment Time</Form.Label>
                                                            <Form.Control as="select" name="apptime" ref={updateatime} onKeyPress={(e) => DisableKeyPress(e)}>
                                                                <option key="" value="">---Select---</option>
                                                                {slottiming}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='mt-2 text-center'>
                                    {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={UpdateAppointmentDateAndTime}>Update</Button>}
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </>
    )
}

export default Appointments