import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import { FrontDesk_URL } from '../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import CommonToolTip from '../UI/ToolTips/CommonToolTip';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from '../commonComponent/LoadingButton';

const RadiologyAppointmentStatus = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const [defaultDate, setDefaultDate] = useState("");
    const [radiologycentre, setRadiologyCentre] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [fromdefaultDate, setFromDefaultDate] = useState("");
    const [orderremarks, setOrderRemarks] = useState("");
    const [orderremarksModal, setOrderRemarksModal] = useState();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    let fromDate = useRef();
    let toDate = useRef();
    let refmobilenumber = useRef();
    let refuhid = useRef();
    let refpatientname = useRef();
    let refvisitid = useRef();
    let sampleStatus = useRef();

    useEffect(() => {
        const today = new Date();
        const today1 = new Date();
        const date = today.setDate(today.getDate() - 7);
        const date1 = today1.setDate(today1.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        const defaultValue2 = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
        setFromDefaultDate(defaultValue);
        setDefaultDate(defaultValue2);
        GetOutsourceRadiologyCentres();
        GetPendingOrderList(defaultValue, defaultValue2);
    }, [])

    const openEditScreen = async (PatientId, VisitingId) => {
        localStorage.setItem("RadioPatientId", PatientId);
        localStorage.setItem("RadioVisitingId", VisitingId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/appointment`, search: `` });
    }
    const popupPaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionBillFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    (rowData.InvoiceNo === "" || rowData.InvoiceNo === null || rowData.InvoiceNo === undefined) ?
                        <>
                            {
                                rowData.OrderStatus === "0" || rowData.OrderStatus === "False" ?
                                    <CommonToolTip title="Edit Items" id={`Edit${rowData.PatientId, rowData.VisitingId}`} placement="top" class="fa fa-edit" onClick={() => openEditScreen(rowData.PatientId, rowData.VisitingId)} />
                                    : null
                            }
                        </> :
                        (rowData.InvoiceNo === "1" || rowData.InvoiceNo === "True") ? null :
                            <span>{rowData.InvoiceNo} </span>
                }
            </div>
        </React.Fragment>
    }
    const actionOrderStatusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.OrderStatus === "1" || rowData.OrderStatus === "True" ? " In Progress" :
                        rowData.OrderStatus === "0" || rowData.OrderStatus === "False" ? " Pending" :
                            rowData.OrderStatus === "-1" ? " Order Cancelled" :
                                rowData.OrderStatus === "3" ? " Report Generated" :
                                    " Rejected"
                }
                {
                    rowData.IsOutsource === "1" || rowData.IsOutsource.toLowerCase() === "true" ? <span>Outsourced to {rowData.OutsourceCentreName}</span> : null
                }
            </div>
        </React.Fragment>
    }
    const actionDownloadStatusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.OrderStatus === "0" || rowData.OrderStatus === "False" ?
                        <CommonToolTip title="View Report" id={`ViewReport${rowData.VisitingId}`} placement="top" class="fa fa-eye" onClick={() => editHandler(rowData.VisitingId)} /> :
                        <>
                            <CommonToolTip title="Print receipt" id={`Printreceipt${rowData.VisitingId}`} placement="top" class="fa fa-print" onClick={() => Printreceipt(rowData.VisitingId)} />
                            &nbsp;&nbsp;
                            <CommonToolTip title="View Report" id={`ViewReport${rowData.VisitingId}`} placement="top" class="fa fa-eye" onClick={() => editHandler(rowData.VisitingId)} />
                        </>
                }
                {
                    rowData.Remarks == "" || rowData.Remarks == null || rowData.Remarks == undefined ? null :
                        <>
                            &nbsp;&nbsp;
                            <CommonToolTip title="Notes" id={`Notes${rowData.VisitingId}`} placement="top" class="fa fa-info-circle mr-2" onClick={() => openOrderRemarksModal(rowData.VisitingId, rowData.Remarks)} />
                        </>
                }
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'Appointment', text: 'Appointment Date & Time', editable: false },
            { dataField: 'VisitingCode', text: 'Visit No', editable: false },
            { dataField: 'OriginalCentreName', text: 'Appointment Centre Name', editable: false },
            { dataField: 'TemplateName', text: 'Rate Template', editable: false },
            { dataField: 'PatientName', text: 'Patient', editable: false },
            { dataField: 'InvoiceNo', text: 'Bill No', editable: false, formatter: actionBillFormatter },
            { dataField: 'OrderTime', text: 'Timestamp', editable: false, },
            { dataField: 'TotalAmount', text: 'Total Amount', editable: false },
            { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
            { dataField: 'OrderStatus', text: 'Status', editable: false, formatter: actionOrderStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
            { dataField: 'Action', text: 'Action', editable: false, formatter: actionDownloadStatusFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        ]

    const editHandler = async (vId) => {
        localStorage.setItem('vId', vId);
        localStorage.setItem('mode', 'appointmentstatus');
        window.open(`${process.env.PUBLIC_URL}/lab-report/overview`, '_blank');
    }
    const Printreceipt = (vId) => {
        localStorage.setItem('vId', vId);
        window.open(`${process.env.PUBLIC_URL}/front-office/receipt`, '_blank');
    }
    const openOrderRemarksModal = async (vId, remarks) => {
        setOrderRemarks(remarks);
        setOrderRemarksModal(!orderremarksModal);
    }
    const GetPendingOrderList = async (fromDate, toDate) => {
        setIsLoading(true);
        let mobno = refmobilenumber.current.value === "" ? "0" : refmobilenumber.current.value;
        let visitid = refvisitid.current.value === "" ? "0" : refvisitid.current.value;
        let uhid = refuhid.current.value === "" ? "0" : refuhid.current.value;
        let sinno = "0";
        let patname = refpatientname.current.value === "" ? "0" : refpatientname.current.value;
        let ordstat = sampleStatus.current.value === "" ? "-1" : sampleStatus.current.value;
        let outcid = (typeSelected.length == 0 || typeSelected == [] ? "0" : typeSelected)
        const requestOptions = {
            CentreId: logincentreid,
            LoginId: loginuserid,
            fromdate: fromDate,
            todate: toDate,
            mobilenumber: mobno,
            visitid: visitid,
            uhid: uhid,
            sinno: sinno,
            patientname: patname,
            samplestatusflag: ordstat,
            outsourcecentreid: outcid
        }
        try {
            let url = FrontDesk_URL + 'master/getpendingorderlistradio'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data.pendingOrderList !== null) {
                        setTable(data.Data.pendingOrderList);
                    }
                    else { setTable([]); }
                }
                else { setTable([]); }
            }).catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const handleSearch = () => {
        if ((fromDate.current.value === "" || fromDate.current.value === undefined) && (toDate.current.value !== "")) {
            setTable([]);
            SweetAlert.fire("Please enter from date");
        }
        else if ((toDate.current.value === "" || toDate.current.value === undefined) && (fromDate.current.value !== "")) {
            setTable([]);
            SweetAlert.fire("Please enter to date");
        }
        else if (fromDate.current.value === "" && toDate.current.value === "" && refmobilenumber.current.value === "" && refvisitid.current.value === "" && refuhid.current.value === "" && refpatientname.current.value === "") {
            setTable([]);
        }
        else if (refmobilenumber.current.value !== "" && (refmobilenumber.current.value.length > 10 || refmobilenumber.current.value.length < 10)) {
            SweetAlert.fire("Please enter correct 10 digit mobile number");
        }
        else { GetPendingOrderList((fromDate.current.value === undefined || fromDate.current.value === "") ? "1900-01-01" : fromDate.current.value, (toDate.current.value === undefined || toDate.current.value === "") ? "1900-01-01" : toDate.current.value); }
    }
    const Reset = async () => {
        refmobilenumber.current.value = "";
        refvisitid.current.value = "";
        refuhid.current.value = "";
        refpatientname.current.value = "";
        setTypeSelected([]);
        sampleStatus.current.value = "2";
        const today = new Date();
        const today1 = new Date();
        const date = today.setDate(today.getDate() - 7);
        const date1 = today1.setDate(today1.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        const defaultValue2 = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
        setFromDefaultDate(defaultValue);
        setDefaultDate(defaultValue2);
        fromDate.current.value = defaultValue;
        toDate.current.value = defaultValue2;
        GetPendingOrderList(defaultValue, defaultValue2);
    }
    const GetOutsourceRadiologyCentres = async () => {
        var glblArr1 = [];
        try {
            let url = FrontDesk_URL + 'master/GetOutsourceRadiologyCentres/' + localStorage.CentreSNo + '/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        glblArr1.push({ 'value': '0', 'label': 'All', 'disabled': false });
                        ds.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'disabled': false }); });
                        setRadiologyCentre(glblArr1);
                    }
                    else {
                        setRadiologyCentre([]);
                    }
                    //setRadiologyCentre(data.Data.map((d) => (<option key={d.CentreId} value={d.CentreId}>{d.CentreName}</option>)));
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const TypeChangeHandler = (options) => { setTypeSelected(options.value); }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Radiology Appointment Status" parent="Work Order" />
            <Form>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Row>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Mobile Number:</Form.Label>
                                                    <Form.Control type="number" min={0} id="mobilenumber" name="mobilenumber" ref={refmobilenumber} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Visit No:</Form.Label>
                                                    <Form.Control type="text" id="visitid" name="visitid" ref={refvisitid} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>UHID:</Form.Label>
                                                    <Form.Control type="text" id="uhid" name="uhid" ref={refuhid} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Patient Name:</Form.Label>
                                                    <Form.Control type="text" id="patientname" name="patientname" ref={refpatientname} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>From date:</Form.Label>
                                                    <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={fromdefaultDate} max={defaultDate} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>To date:</Form.Label>
                                                    <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} max={defaultDate} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Control as="select" id="sample" name="sample" ref={sampleStatus} >
                                                        <option value="2" selected>All</option>
                                                        <option value="1">In progress</option>
                                                        <option value="0">Pending</option>
                                                        <option value="-1">Order Cancelled</option>
                                                        <option value="3">Report Generated</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Appointment Centre</Form.Label>
                                                    <Select onChange={TypeChangeHandler}
                                                        options={radiologycentre}
                                                        value={radiologycentre.filter(function (x) { return x.value === typeSelected; })}
                                                        placeholder='All' />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className='mt15'>
                                            <Col md={12} className='text-center'>
                                                <Button variant='secondary' onClick={Reset} className='mr-2'>Reset</Button>
                                                <Button variant='primary' onClick={handleSearch}>Search</Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Form.Row>
                                        <Col md={8}>
                                            <Card.Title>Appointment Status</Card.Title>
                                        </Col>
                                    </Form.Row>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        isLoading ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <DataTables
                                                keyField='VisitingId'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(popupPaginationOptions)}
                                            />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Form>
            <Modal isOpen={orderremarksModal} toggle={openOrderRemarksModal} centered={true} size="lg">
                <ModalHeader toggle={openOrderRemarksModal}>Notes</ModalHeader>
                <ModalBody className='modalvariation'>
                    {
                        orderremarks == "" ? null :
                            <Row>
                                <Col md={12}>
                                    <Card className='routeCard'>
                                        <Card.Body>
                                            <Row className='mb-5'>
                                                <Col xs={12}>
                                                    <p>{orderremarks}</p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col md={12} className='mt-2 text-center'>
                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={openOrderRemarksModal}>Close</Button>}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default RadiologyAppointmentStatus