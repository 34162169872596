import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DynamicDataTable from '../commonComponent/DataTable/DynamicDataTable';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

const ServiceWiseCollections = () => {
    let fromDate = useRef();
    let toDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [CentreId, setCentreId] = useState(localStorage.CentreSNo == undefined ? "" : localStorage.CentreSNo);
    const [LoginId, setLoginId] = useState(localStorage.uId == undefined ? "" : localStorage.uId);
    // const [table, setTable] = useState([]);
    const [tableData, setTableData] = useState({
        PageNo: 1,
        PageSize: 100,
        TotalRecord: 0,
        Rows: []

    });
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        BindGrid(defaultValue, defaultValue,tableData.PageNo, tableData.PageSize);
    }, []);
    const paginationOptions = {
        totalSize: tableData.TotalRecord,
        sizePerPage: tableData.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); BindGrid(fromDate.current.value, toDate.current.value,page, sizePerPage); },
        onSizePerPageChange: (sizePerPage, page, event) => { BindGrid(fromDate.current.value, toDate.current.value,page, sizePerPage); document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show'); },
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'ClientCode', text: 'Client Code', editable: false },
    { dataField: 'ClientName', text: 'Client Name', editable: false },
    { dataField: 'PartnerType', text: 'Partner Type', editable: false },
    { dataField: 'VisitingCode', text: 'Visiting Code', editable: false },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'OrderDate', text: 'Order Date', editable: false },
    { dataField: 'BillNo', text: 'Bill No', editable: false },
    { dataField: 'BillDate', text: 'Bill Date', editable: false },
    { dataField: 'PatientName', text: 'Name', editable: false },
     { dataField: 'ItemName', text: 'Item Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'SAPCode', text: 'SAP Code', editable: false },
    { dataField: 'GrossAmount', text: 'Gross Amount', editable: false },
    { dataField: 'Discount', text: 'Discount', editable: false },
    { dataField: 'DiscountRemark', text: 'DiscountRemark', editable: false },
    { dataField: 'NetAmount', text: 'NetAmount', editable: false },
    { dataField: 'PaymentStatus', text: 'Payment Status', editable: false },
    { dataField: 'Panel', text: 'Panel', editable: false },
    { dataField: 'RatePlan', text: 'RatePlan', editable: false },
    { dataField: 'Source', text: 'Source', editable: false }
    ];
    const BindGrid = async (fromDateSelected, toDateSelected,PageNo,PageSize) => {
        setIsLoading(true);
        const  ObjServiceCollection= {
            CentreId:localStorage.getItem('CentreSNo'),
            FromDate:fromDateSelected,
            ToDate:toDateSelected,
            PageNo:`${PageNo}`,
            PageSize:`${PageSize}`,
            IsExcelD:`${0}`
        };        
        try {
            let url = FrontDesk_URL + 'reports/GetServicewiseCollectionReport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { 
                         if(data.Data.SerViceList!=null && data.Data.SerViceList.length>0)
                        {
                            setTableData({
                                ...tableData,
                                PageSize: Number(PageSize),
                                TotalRecord: Number(data.Data.TotalRecord),
                                Rows: data.Data.SerViceList
                            });
                        }
                        
                        //setTable(data.Data);
                     }
                    else {  setTableData([]); }
                    setIsLoading(false);
                }
                setIsLoading(false);
            }).catch(function (error) { setIsLoading(false);} );
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false);}
    }
    const GetReportData = async (fromDateSelected, toDateSelected,PageNo,PageSize) => {
        setIsLoading(true);
        setDownloadVisible(false);
        const  ObjServiceCollection= {
            CentreId:localStorage.getItem('CentreSNo'),
            FromDate:fromDateSelected,
            ToDate:toDateSelected,
            PageNo:`${PageNo}`,
            PageSize:`${PageSize}`,
            IsExcelD:`${1}`
        };        
        try {
            let url = FrontDesk_URL + 'reports/GetServicewiseCollectionReport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.SerViceList.length>0) {
                        setDownloadVisible(true);
                        setCSVData(data.Data.SerViceList);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
                setIsLoading(false);
            }).catch(function (error) { });
            setIsLoading(false);
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        setIsLoading(false);
    }
    const handleSearch = async () => {
        BindGrid(fromDate.current.value, toDate.current.value,tableData.PageNo, tableData.PageSize);
    }
    const handleDownload = async () => {
        GetReportData(fromDate.current.value, toDate.current.value,tableData.PageNo, tableData.PageSize);
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Servicewise Collection" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='topmargin30'>
                                        <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                    </Col>
                                    <Col lg={12} md={12} className='TableDynamic' style={{ position: 'relative' }}>
                                        {
                                            isLoading ?
                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <>
                                            <DynamicDataTable 
                                                keyField='Id'
                                                tableData={tableData.Rows}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                            />
                                            </>
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="ServicewiseReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
          
}
export default ServiceWiseCollections;